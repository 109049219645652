import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, getFormValues } from 'redux-form';
import { Form } from 'react-bootstrap';
import { compose } from 'redux';
import InternalButtonBar from '../../../layout/components/InternalButtonBar/InternalButtonBar';
import { InputRow, Button, FullRow, BackButton } from '../../../../common/components';
import PermissionsList from '../PermissionsList/PermissionsList';
import { required } from 'redux-form-validators';

const FORM_NAME = 'roleForm';

const LABEL_SIZES = [4, 4, 3, 3, 3];
const FIELD_SIZES = [8, 8, 9, 9, 9];

const RoleForm = ({
  handleSubmit,
  saveRole,
  permissions,
  formValues,
  submitting,
  array,
  resource,
}) => {
  const togglePermission = (permission) => {
    const formPermissions = formValues.data.relationships.permissions.data;
    const permissionIndex = formPermissions.findIndex((formPermission) => {
      return formPermission.id === permission;
    });
    if (permissionIndex >= 0) {
      array.splice('data.relationships.permissions.data', permissionIndex, 1);
    } else {
      array.push('data.relationships.permissions.data', { id: permission });
    }
  };

  const permissionCategories = permissions.reduce((carry, permission) => {
    const { category } = permission.attributes;

    if (carry[category]) {
      carry[category].push(permission);
    } else {
      carry[category] = [permission];
    }
    return carry;
  }, {});

  const existingPermissions = formValues?.data.relationships.permissions.data;
  const roleName = formValues?.data?.attributes?.name
    ? formValues.data.attributes.name
    : 'New Role';

  return (
    <>
      <h2 className="resource-name">{roleName}</h2>
      <Form className="role-form" onSubmit={handleSubmit(saveRole)}>
        <InputRow
          name="data.attributes.name"
          labelSizes={LABEL_SIZES}
          fieldSizes={FIELD_SIZES}
          label="Name"
          validate={required({ msg: 'Name is required' })}
        />
        <InputRow
          name="data.attributes.description"
          labelSizes={LABEL_SIZES}
          fieldSizes={FIELD_SIZES}
          label="Description"
        />
        <hr />
        <PermissionsList
          permissions={permissionCategories}
          onPermissionChange={togglePermission}
          existingPermissions={existingPermissions}
        />
        <FullRow>
          <InternalButtonBar
            buttons={[
              {
                permissions: ['role.edit'],
                button: (
                  <Button
                    key="edit-role"
                    className="pull-right"
                    type="submit"
                    variant="primary"
                    isLoading={submitting}
                    label="Save Role"
                  />
                ),
              },
              {
                button: (
                  <BackButton
                    key="back"
                    to={
                      resource.data?.id
                        ? `/admin/roles/${resource.data.id}`
                        : '/admin/roles'
                    }
                  />
                ),
              },
            ]}
          />
        </FullRow>
      </Form>
    </>
  );
};

const getInitialValues = (resource) => () => resource;
export default compose(
  connect(
    (state, ownProps) => ({
      initialValues: getInitialValues(ownProps.resource)(state),
      formValues: getFormValues(FORM_NAME)(state),
    }),
    null,
  ),
  reduxForm({ form: FORM_NAME }),
)(RoleForm);
