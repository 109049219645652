import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form';
import { Form } from 'react-bootstrap';
import { compose } from 'redux';
import InternalButtonBar from '../../../layout/components/InternalButtonBar/InternalButtonBar';
import { InputRow, Button, FullRow, BackButton } from '../../../../common/components';
import { required } from 'redux-form-validators';

const FORM_NAME = 'medicalQuestionForm';

const LABEL_SIZES = [4, 4, 3, 3, 3];
const FIELD_SIZES = [8, 8, 9, 9, 9];

const MedicalQuestionForm = ({ handleSubmit, saveQuestion, submitting, resource }) => {
  return (
    <>
      <h2 className="resource-name">Question</h2>
      <Form className="question-form" onSubmit={handleSubmit(saveQuestion)}>
        <InputRow
          name="data.attributes.question"
          labelSizes={LABEL_SIZES}
          fieldSizes={FIELD_SIZES}
          label="Question"
          validate={required({ msg: 'Question is required' })}
        />
        <FullRow>
          <InternalButtonBar
            buttons={[
              {
                permissions: ['medical_library.modify'],
                button: (
                  <Button
                    key="edit-question"
                    className="pull-right"
                    type="submit"
                    variant="primary"
                    isLoading={submitting}
                    label="Save Question"
                  />
                ),
              },
              {
                button: (
                  <BackButton
                    key="back"
                    to={
                      resource.data?.id
                        ? `/library/questions/${resource.data.id}`
                        : '/library/questions'
                    }
                  />
                ),
              },
            ]}
          />
        </FullRow>
      </Form>
    </>
  );
};

const getInitialValues = (resource) => () => resource;
export default compose(
  connect(
    (state, ownProps) => ({
      initialValues: getInitialValues(ownProps.resource)(state),
      formValues: getFormValues(FORM_NAME)(state),
    }),
    null,
  ),
  reduxForm({ form: FORM_NAME }),
)(MedicalQuestionForm);
