import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { reduxForm, getFormValues, FieldArray } from 'redux-form';
import { Form } from 'react-bootstrap';
import { compose } from 'redux';
import InternalButtonBar from '../../../layout/components/InternalButtonBar/InternalButtonBar';
import { InputRow, Button, FullRow, BackButton } from '../../../../common/components';
import AnswersSection from './AnswersSection';
import getIncludedResource from '../../../../helpers/getIncludedResource';
import { required } from 'redux-form-validators';

const FORM_NAME = 'medicalAnswerSetForm';

const LABEL_SIZES = [4, 4, 3, 3, 3];
const FIELD_SIZES = [8, 8, 9, 9, 9];

const MedicalAnswerSetForm = ({
  handleSubmit,
  saveAnswerSet,
  submitting,
  resource,
  formValues,
  array,
  change,
  dispatch,
}) => {
  const answerSetName = formValues?.data?.attributes?.name
    ? formValues.data.attributes.name
    : 'New Answer Set';
  const answers = resource
    ? getIncludedResource(resource.data, resource.included, 'answers')
    : [];

  useEffect(() => {
    if (Array.isArray(answers)) {
      dispatch(
        change(
          'data.meta.answers',
          Object.keys(answers).map((key) => {
            return { id: answers[key].id, answer: answers[key].attributes?.answer };
          }),
        ),
      );
    } else {
      dispatch(change('data.meta.answers', []));
    }
  }, []);

  const reorderAnswers = (path, startIndex, endIndex) => {
    array.move(path, startIndex, endIndex);
  };

  return (
    <>
      <h2 className="resource-name">{answerSetName}</h2>
      <Form className="answer-set-form" onSubmit={handleSubmit(saveAnswerSet)}>
        <InputRow
          name="data.attributes.name"
          labelSizes={LABEL_SIZES}
          fieldSizes={FIELD_SIZES}
          label="Name"
          validate={required({ msg: 'Name is required' })}
        />
        <hr />
        <h2>Answers</h2>
        <FieldArray
          name="data.meta.answers"
          component={AnswersSection}
          reorderAnswers={reorderAnswers}
        />
        <FullRow>
          <InternalButtonBar
            buttons={[
              {
                permissions: ['medical_library.modify'],
                button: (
                  <Button
                    key="edit-answer-set"
                    className="pull-right"
                    type="submit"
                    variant="primary"
                    isLoading={submitting}
                    label="Save Answer Set"
                  />
                ),
              },
              {
                button: (
                  <BackButton
                    key="back"
                    to={
                      resource.data?.id
                        ? `/library/answer-sets/${resource.data.id}`
                        : '/library/answer-sets'
                    }
                  />
                ),
              },
            ]}
          />
        </FullRow>
      </Form>
    </>
  );
};

const getInitialValues = (resource) => () => resource;
export default compose(
  connect(
    (state, ownProps) => ({
      initialValues: getInitialValues(ownProps.resource)(state),
      formValues: getFormValues(FORM_NAME)(state),
    }),
    null,
  ),
  reduxForm({ form: FORM_NAME }),
)(MedicalAnswerSetForm);
