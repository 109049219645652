import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, getFormValues, FormSection } from 'redux-form';
import { Form } from 'react-bootstrap';
import { compose } from 'redux';
import InternalButtonBar from '../../../layout/components/InternalButtonBar/InternalButtonBar';
import {
  InputRow,
  Button,
  FullRow,
  BackButton,
  NameFields,
  SelectMenuAsyncRow,
  SelectMenuMultiRow,
} from '../../../../common/components';
import { required } from 'redux-form-validators';

const FORM_NAME = 'userForm';

const LABEL_SIZES = [4, 4, 3, 3, 3];
const FIELD_SIZES = [8, 8, 9, 9, 9];

const UserForm = ({
  handleSubmit,
  saveUser,
  formValues,
  submitting,
  resource,
  dispatch,
  change,
}) => {
  const userName =
    formValues?.data?.attributes?.first_name && formValues?.data?.attributes?.last_name
      ? formValues.data.attributes.first_name + ' ' + formValues.data.attributes.last_name
      : 'New User';

  return (
    <>
      <h2 className="resource-name">{userName}</h2>
      <Form className="user-form" onSubmit={handleSubmit(saveUser)}>
        <FormSection name="data.attributes">
          <NameFields
            label="Name"
            labelSizes={LABEL_SIZES}
            fieldSizes={[5, 5, 5, 5, 4]}
            hideTitle
            validate={required({ msg: 'Name is required' })}
          />
          <InputRow
            name="email"
            labelSizes={LABEL_SIZES}
            fieldSizes={FIELD_SIZES}
            label="Email"
            validate={required({ msg: 'Email is required' })}
          />
        </FormSection>
        <SelectMenuAsyncRow
          name="data.relationships.organisations.data[0]"
          label="Organisation"
          labelSizes={LABEL_SIZES}
          fieldSizes={FIELD_SIZES}
          url="organisations"
        />
        <SelectMenuMultiRow
          label="Roles"
          labelSizes={LABEL_SIZES}
          fieldSizes={FIELD_SIZES}
          name="data.relationships.roles.data"
          source="roles"
          sourceKey="MultiRoles"
          isObject
          isClearable
        />

        <FullRow>
          <InternalButtonBar
            buttons={[
              {
                permissions: ['user.edit'],
                button: (
                  <Button
                    key="edit-user"
                    className="pull-right"
                    type="submit"
                    variant="primary"
                    isLoading={submitting}
                    label="Save User"
                  />
                ),
              },
              {
                button: (
                  <BackButton
                    key="back"
                    to={
                      resource.data?.id
                        ? `/admin/users/${resource.data.id}`
                        : '/admin/users'
                    }
                  />
                ),
              },
            ]}
          />
        </FullRow>
      </Form>
    </>
  );
};

const getInitialValues = (resource) => () => resource;
export default compose(
  connect(
    (state, ownProps) => ({
      initialValues: getInitialValues(ownProps.resource)(state),
      formValues: getFormValues(FORM_NAME)(state),
    }),
    null,
  ),
  reduxForm({ form: FORM_NAME }),
)(UserForm);
